import classNames from "classnames";
import { NavLink, useHistory } from "react-router-dom";
import GoogleTranslate from "../../components/GoogleTranslate/GoogleTranslate";

// Assets
import logo from "../../assets/images/footer-logo.png";

import styles from "./Footer.module.scss";

export const Footer = () => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={classNames(styles.section, styles.info)}>
          <img src={logo} alt="logo" className={styles.logo} />
          <p style={{ width: "90%" }}>
            Agencija Spektar je najstarija agencija za posredovanje pri
            zapošljavanju u BiH, prepoznatljiva konsultantska kuća za razvoj
            efikasnijih organizacionih rješenja i unapređenje HR procesa u
            organizacijama.
          </p>
        </div>
        <div className={classNames(styles.section, styles.links)}>
          <NavLink to="/" className={styles.link}>
            Početna
          </NavLink>
          <NavLink to="/svi-poslovi/1" className={styles.link}>
            Svi poslovi
          </NavLink>
          <NavLink
            to="/oglasi-iz-sredstava-informisanja/1"
            className={styles.link}
          >
            Oglasi iz sredstava informisanja
          </NavLink>
          <NavLink to="/pitanja" className={styles.link}>
            Najčešća pitanja
          </NavLink>
          <NavLink to="/mediji/1" className={styles.link}>
            Mediji
          </NavLink>
          <NavLink to="/korisni-savjeti" className={styles.link}>
            Korisni savjeti
          </NavLink>
          <NavLink to="/obuke" className={styles.link}>
            Obuke
          </NavLink>
          <NavLink to="/studentski-servis" className={styles.link}>
            Studentski servis
          </NavLink>
          <NavLink to="/trazite-radnike" className={styles.link}>
            Tražite radnike
          </NavLink>
          <NavLink to="/poslovno-savjetovanje" className={styles.link}>
            Poslovno savjetovanje
          </NavLink>
          <NavLink to="/karijerno-savjetovanje" className={styles.link}>
            Karijerno savjetovanje
          </NavLink>
          <NavLink to="/blog/1" className={styles.link}>
            Blog
          </NavLink>
          <NavLink to="/arhiva/1" className={styles.link}>
            Arhiva poslova
          </NavLink>
        </div>
        <div className={classNames(styles.section, styles.contact)}>
          <p>info@agencijaspektar.com</p>
          <p>Bana Milosavljevića br. 8 </p> <p>78000 Banja Luka</p>
          <p>
            Telefon: +387 51 321 930 <br /> Fax: +387 51 321 931 <br /> Viber:
            +387 65 200 444
          </p>
          <div className={styles.socialMedia}>
            <a
              href="https://www.facebook.com/agencijaspektar"
              rel="noreferrer"
              target="_blank"
            >
              <div className={styles.facebook}></div>
            </a>
            <a
              href="https://www.instagram.com/agencijaspektar/"
              rel="noreferrer"
              target="_blank"
            >
              <div className={styles.instagram}></div>
            </a>
            <a
              href="https://www.linkedin.com/company/posaouzposredovanje/"
              rel="noreferrer"
              target="_blank"
            >
              <div className={styles.linkedin}></div>
            </a>
            <a
              href="https://twitter.com/AgencijaSpektar"
              rel="noreferrer"
              target="_blank"
            >
              <div className={styles.twitter}></div>
            </a>
            <a
              href="https://www.youtube.com/@agencijaspektar"
              rel="noreferrer"
              target="_blank"
            >
              <div className={styles.youtube}></div>
            </a>
          </div>
        </div>
      </div>
      <div className={styles.copyright}>
        <div style={{ display: "flex", gap: "10px" }}>
          <p>&copy;2023 Agencija Spektar </p>
          <p>|</p>
          <p> Sva prava zadržana.</p>
        </div>
        <p
          onClick={() => history.push("/politika-privatnosti")}
          style={{ cursor: "pointer" }}
        >
          Politika privatnosti
        </p>
        <GoogleTranslate />
      </div>
    </div>
  );
};
